// export const api = 'http://45.144.154.159:2829/api/v1';
// export const api = 'http://45.144.154.85:2829/api/v1';
// export const api = 'http://localhost:8080/api/v1';
// export const api = 'https://crm.api.clrsoft.xyz/api/v1';
export const api = "https://crmapi.fusionpropanel.com/api/v1";

export const momentDateFormat = "DD.MM.yyyy HH:mm";
export const momentTimeFormat = "HH:mm";
export const momentURLFormat = "yyyy-MM-DD HH:mm:ss";

export const dataEkle = "data_ekle";
export const dataGuncelleme = "data_guncelleme";
export const dataSilme = "data_silme";
export const topluMusteriEkle = "toplu_musteri_ekle";
export const notGoruntuleme = "not_goruntuleme";
export const notEkleme = "not_ekleme";

export const anasayfa = "anasayfa";

export const takimMenu = "takim_menu";
export const takimDetay = "takim_detay";
export const takimEkle = "takim_ekle";
export const takimListesi = "takim_listesi";

export const personelMenu = "personel_menu";
export const personelDetay = "personel_detayı";
export const personelEkle = "personel_ekle";
export const personelListesi = "personel_listesi";
export const personelSilme = "personel_silme";

export const kaynakMenu = "kaynak_menu";
export const kaynakEkle = "kaynak_ekle";
export const kaynakDetay = "kaynak_detay";
export const kaynakListesi = "kaynak_listesi";

export const leadMenu = "lead_menu";
export const leadEkle = "lead_ekle";
export const leadDetay = "lead_detay";
export const leadListesi = "lead_listesi";

export const rolMenu = "rol_menu";
export const rolEkleme = "rol_ve_yetkilendirme";
export const rolListesi = "rol_ve_yetkilendirme_listesi";
export const rolDetay = "rol_ve_yetkilendirme_detay";

export const musteriMenu = "musteri_menu";
export const musteriDetay = "musteri_detay";
export const musteriAdi = "musteri_adi";
export const musteriSoyadi = "musteri_soyadi";
export const musteriEkle = "musteri_ekle";
export const satisMusteriEkle = "satis_musteri_ekle";
export const musteriSilme = "musteri_silme";
export const musteriListesi = "musteri_listesi";
export const musteriIndirme = "musteri_indirme";
export const musteriEMailAdresi = "musteri_email_adresi";
export const musteriRaporCekme = "musteri_rapor_cekme";
export const musteriSorumluPersonel = "musteri_sorumlu_personel";
export const musteriTelefonNumarasi = "musteri_telefon_numarasi";
export const musteriGuncelle = "musteri_guncelle";
export const musteriListesiTopluGuncelleme = "musteri_listesi_toplu_guncelleme";
export const musteriListesiSorumluPersonelGecmisi =
  "musteri_listesi_sorumlu_kisi_gecmisi";
export const musteriListesiLeadGuncelleme = "musteri_listesi_lead_guncelleme";
export const musteriListesiAtamaGuncelleme = "musteri_listesi_atama_guncelleme";
export const googleSheetsListesi = "google_sheet_listesi";
export const dokumanMenu = "dokuman_menu";

export const destekListesi = "destek_listesi";
export const destekOlusturma = "destek_olusturma";
export const destekMenu = "destek_menu";

export const hatirlaticiEkle = "hatirlatici_ekle";
export const hatirlaticiListesi = "hatirlatici_listesi";

/*Önemli Yetkiler */
export const raporMenu = "rapor_menu";
export const yonetimMenu = "yonetim_menu";
export const tumMusteriNotlar = "tum_musteri_notlari";
export const tumHareketGecmisi = "tum_hareket_gecmisi";
export const tumMusteriSorumluKisiGecmisi = "tum_musteri_sorumlu_kisi_gecmisi";
export const yeniGelistirmeBilgileri = "yeni_gelistirme_bilgileri";
export const meta4Listeleri = "meta4_listeleri";
export const customerInfoAggregation = "customer_info_aggregation";
export const lngs = {
  en: { nativeName: "English" },
  tr: { nativeName: "Turkish" },
};
export const languageList = [
  { name: "Türkçe", code: "tr" },
  { name: "İngilizce", code: "en" },
];

export const ticketImportanceLevel = [
  { name: "Düşük", value: "LOW" },
  { name: "Normal", value: "MEDIUM" },
  { name: "Yüksek", value: "HIGH" },
];